import { useEffect, useState } from 'react';

import { getRemoteConfigData } from '../data/data-merger';

export type RemoteConfig = {
  cognitoRegion: string;
  pinpointID: string;
  region: string;
};

export const useRemoteConfig = () => {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);

  useEffect(() => {
    if (!remoteConfig) {
      void getRemoteConfigData().then(nextRemoteConfig => setRemoteConfig(nextRemoteConfig));
    }
  }, [remoteConfig]);

  return remoteConfig;
};

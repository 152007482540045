import { CatalogEntry } from '../../data/CatalogEntry';
import { Text } from '../common/text/Text';
import { CatalogListItem } from './CatalogListItem';
import './SessionListItem.css';

interface FAQ extends CatalogEntry {
  question: string;
  answer: string;
}

interface FAQListItemProps {
  faq: FAQ;
}

const FAQListItem: React.FC<FAQListItemProps> = (props) => {
  const getExpandedChildren = () => {
    if (props.faq.answer) {
      return (
        <p>
          <Text>{props.faq.answer}</Text>
        </p>
      );
    }
  };

  return (
    <CatalogListItem
      type="FAQ"
      title={`${props.faq.question}`}
      expandedChildren={getExpandedChildren()}
    />
  );
};

export { FAQListItem };
export type { FAQ };

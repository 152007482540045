import React, { ChangeEventHandler, MutableRefObject } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import './Input.css';

interface InputProps {
  onChange?: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  size?: string;
  testId?: string;
  width?: string;
  keyboardRef?: MutableRefObject<KeyboardReactInterface | null>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Input: React.FC<InputProps> = (props) => {
  const inputChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    updateValue(event.target.value);
  };

  const clearClickHandler = () => {
    updateValue('');
    if (props.keyboardRef?.current) {
      props.keyboardRef.current.clearInput();
    }
  };

  const updateValue = (newValue: string) => {
    props.setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const height = 60;
  const width = props.width ?? '100%';

  return (
    <div className="input-wrapper" style={{ width }}>
      <input
        data-testid={props.testId}
        style={{ height }}
        value={props.value}
        onChange={inputChangeHandler}
        onFocus={props.onFocus}
        className="input"
        placeholder={props.placeholder}
      />
      {props.value && (
        <MdOutlineCancel
          className="input-clear"
          color="var(--color-divider)"
          size={`calc(${height} * .75)`}
          style={{ right: `calc(${height} * .15)` }}
          onClick={clearClickHandler}
        />
      )}
    </div>
  );
};

export { Input };

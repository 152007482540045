import React, { MutableRefObject, useCallback } from 'react';
import { KeyboardReactInterface } from 'react-simple-keyboard';

import { Filter } from './Filter.types';
import { VisibleScreen } from '../../App';
import { Input } from '../common/input/Input';
import { Pill } from '../common/pillbox/Pill';
import { PillUpdate } from '../common/pillbox/PillUpdate.types';
import { Pillbox } from '../common/pillbox/Pillbox';
import { Text } from '../common/text/Text';

import './CatalogHeader.css';

interface CatalogHeaderProperties {
  visibleScreen: VisibleScreen;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  title?: string;
  filter?: Filter;
  keyboardRef?: MutableRefObject<KeyboardReactInterface | null>;
  onClickSearchButton?: () => void;
  onSearch?: (searchValue: string) => void;
  onInputFocus?: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handlePastSessionChange: () => void;
  showPastSession: boolean;
}

const CatalogHeader: React.FC<CatalogHeaderProperties> = (props) => {
  const inputChangeHandler = useCallback(
    (value: string) => {
      props.setInputValue(value);
      if (props.keyboardRef?.current) {
        props.keyboardRef.current.setInput(value);
      }
      if (props.onSearch) {
        props.onSearch(value.toLowerCase().trim());
      }
    },
    [props],
  );

  const pillChangeHandler = useCallback(
    (change: PillUpdate) => {
      let newRoute: VisibleScreen = 'Catalog-All';
      if (change.pill === 'Session') {
        newRoute = 'Catalog-Sessions';
      } else if (change.pill === 'FAQ') {
        newRoute = 'Catalog-FAQs';
      } else if (change.pill === 'Place') {
        newRoute = 'Catalog-Navigate';
      }

      props.setVisibleScreen(newRoute);
    },
    [props],
  );

  return (
    <div className="catalog-header">
      {props.title && (
        <Text testId="catalog-header-title" className="catalog-header-title">
          {props.title}
        </Text>
      )}
      <Pillbox
        values={props.filter}
        onPillChange={pillChangeHandler}
        setVisibleScreen={props.setVisibleScreen}
        handlePastSessionChange={props.handlePastSessionChange}
        showPastSession={props.showPastSession}
      />
      <div className="catalog-header__search-filter">
        <div className="catalog-header__search-bar">
          <Input
            testId="catalog-header-search-input"
            size="4vh"
            placeholder="Tap to search"
            onChange={inputChangeHandler}
            onFocus={props.onInputFocus}
            value={props.inputValue}
            setValue={props.setInputValue}
          />
        </div>
        <div className="search-button">
          <Pill
            isTall
            label="Search"
            selected={false}
            onClick={props.onClickSearchButton}
          />
        </div>
      </div>
    </div>
  );
};

export { CatalogHeader };

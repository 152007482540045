import Amplify from 'aws-amplify';
import { useEffect, useState } from 'react';

import { config } from '../config';
import { RemoteConfig, useRemoteConfig } from './UseRemoteConfig';

const {
  AMPLIFY_IDENTITY_POOL_ID,
  AMPLIFY_USER_POOL_ID,
  AMPLIFY_USER_POOL_WEB_CLIENT_ID
} = config;

const useAmplify = () => {
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState(false);
  const remoteConfig = useRemoteConfig();

  useEffect(() => {
    // I haven't found a way of checking if amplify is configured, so
    // unfortunately, it would be reconfigured whenever this hook is used. The
    // state value here just keeps it from re-configuring every time the host
    // component updates.
    if (!isAmplifyConfigured && remoteConfig) {
      configureAmplify(remoteConfig);
      setIsAmplifyConfigured(true);
    }
  }, [isAmplifyConfigured, remoteConfig]);

  return isAmplifyConfigured;
};

const configureAmplify = (remoteAppConfig: RemoteConfig) => {
  Amplify.configure({
    Auth: {
      identityPoolId: AMPLIFY_IDENTITY_POOL_ID,
      region: remoteAppConfig.cognitoRegion,
      userPoolId: AMPLIFY_USER_POOL_ID,
      userPoolWebClientId: AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    },
    Analytics: {
      // This prevents amplify from sending an UpdateEndpoint call to pinpoint
      // when Amplify is configured. We don't support this call for security
      // purposes.
      autoSessionRecord: false,
      AWSPinpoint: {
        appId: remoteAppConfig.pinpointID,
        region: remoteAppConfig.cognitoRegion,
      },
    },
  });
};

export { useAmplify };

import { useCallback, useEffect, useRef, useState } from 'react';

interface UseTimerProps {
  onStart?: (duration: number, elapsed: number) => void;
  onStop?: (duration: number, elapsed: number) => void;
  onTick?: (duration: number, elapsed: number) => void;
  onTimeout?: (duration: number, elapsed: number) => void;
}

interface UseTimerReturn {
  status: string;
  duration: number;
  elapsed: number;
  handleStart: (startDuration: number) => void;
  handleStop: () => void;
}

const INCREASED_ELAPSED_BY = 1;
const CALL_INTERVAL_BY = 1000;

const usePrevious = (value: string) => {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const timerStatus = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  TIMED_OUT: 'TIMED_OUT',
};

const useTimer = ({
  onStart,
  onStop,
  onTick,
  onTimeout,
}: UseTimerProps): UseTimerReturn => {
  const [status, setStatus] = useState(timerStatus.STOPPED);
  const previousStatus = usePrevious(status);
  const [duration, setDuration] = useState(0);
  const [elapsed, setElapsed] = useState(0);

  const reset = useCallback((resetDuration = 0) => {
    setDuration(resetDuration);
    setElapsed(0);
  }, []);

  const handleStart = useCallback(
    (startDuration: number) => {
      reset(startDuration);
      setStatus(timerStatus.RUNNING);
    },
    [reset],
  );

  const handleStop = useCallback(() => setStatus(timerStatus.STOPPED), []);

  useInterval(
    () =>
      setElapsed((previousElapsed) => previousElapsed + INCREASED_ELAPSED_BY),
    status === timerStatus.RUNNING ? CALL_INTERVAL_BY : null,
  );

  // Handle tick event.
  useEffect(() => {
    if (elapsed > 0) {
      onTick?.(duration, elapsed);
    }
  }, [elapsed, duration, onTick]);

  // Check for timeout event.
  useEffect(() => {
    if (duration > 0 && elapsed === duration) {
      setStatus(timerStatus.TIMED_OUT);
    }
  }, [duration, elapsed]);

  // Handle all other events.
  useEffect(() => {
    const { RUNNING, STOPPED, TIMED_OUT } = timerStatus;

    // Started
    if (
      status === RUNNING &&
      (previousStatus === STOPPED || previousStatus === TIMED_OUT)
    ) {
      return onStart?.(duration, elapsed);
    }

    // Stopped (not initial state)
    if (status === STOPPED && previousStatus && previousStatus !== STOPPED) {
      onStop?.(duration, elapsed);
      return reset();
    }

    // Timed out
    if (status === TIMED_OUT) {
      onTimeout?.(duration, elapsed);
      return reset();
    }
  }, [
    status,
    duration,
    elapsed,
    onStart,
    onStop,
    onTimeout,
    previousStatus,
    reset,
  ]);

  return {
    status,
    duration,
    elapsed,
    handleStart,
    handleStop,
  };
};

export { useTimer };
export type { UseTimerReturn };

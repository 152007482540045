import './Switch.css';

interface SwitchProps {
  id: string;
  label: string;
  onChange: () => void;
  checked: boolean;
}

const Switch: React.FC<SwitchProps> = ({ id, label, onChange, checked }) => {
  return (
    <div className="switch-container">
      <span className="title">{label}</span>
      <div className="switch">
        <input type="checkbox" id={id} onChange={onChange} checked={checked} />
        <label htmlFor={id}>
          <span />
        </label>
      </div>
    </div>
  );
};

export { Switch };

import { useCallback, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import Schedule from 'react-schedule-job';

import { IdleTimer } from './components/common/timeout/IdleTimeout';
import { Loading } from './components/loading/Loading';
import { CatalogEntry } from './data/CatalogEntry';
import { getAllApiData, getAllLocalData } from './data/data-merger';
import { Catalog } from './screens/Catalog';
import { Intro } from './screens/Intro';
import { Map } from './screens/Map';

export type VisibleScreen =
  | 'Intro'
  | 'Map'
  | 'Map-Reset'
  | 'Catalog-All'
  | 'Catalog-All-Reset'
  | 'Catalog-Sessions'
  | 'Catalog-Sessions-Reset'
  | 'Catalog-FAQs'
  | 'Catalog-FAQs-Reset'
  | 'Catalog-Navigate'
  | 'Catalog-Navigate-Reset';

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [visibleScreen, setVisibleScreen] = useState<VisibleScreen>('Map');
  const [destinationPOIId, setDestinationPOIId] = useState('');
  const [catalogEntriesApi, setCatalogEntriesApi] = useState<CatalogEntry[]>();
  const [catalogEntriesLocal, setCatalogEntriesLocal] =
    useState<CatalogEntry[]>();
  const catalogEntriesToUse =
    catalogEntriesApi && catalogEntriesApi.length > 0
      ? catalogEntriesApi
      : catalogEntriesLocal;

  const cacheAllLocalData = useCallback(() => {
    const localData = getAllLocalData();
    setCatalogEntriesLocal(localData);
  }, [setCatalogEntriesLocal]);

  const refreshAllApiData = useCallback(async () => {
    const apiData = await getAllApiData();
    if (apiData.length > 0) {
      setCatalogEntriesApi(apiData);
    }
  }, [setCatalogEntriesApi]);

  const fetchStaticData = () => cacheAllLocalData();
  const fetchDynamicData = () => refreshAllApiData();

  useEffect(() => {
    cacheAllLocalData();
    void refreshAllApiData();
  }, [cacheAllLocalData, refreshAllApiData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visibleScreen]);

  const jobs = [
    {
      fn: fetchStaticData,
      id: '1',
      schedule: '0 7 * * *',
      name: 'static-data',
    },
    {
      fn: fetchDynamicData,
      id: '2',
      schedule: '0 * * * *',
      name: 'dynamic-data',
    },
  ];

  return (
    <div>
      <IconContext.Provider
        value={{
          color: 'var(--color-text-primary)',
          className: 'react-icon',
          size: '3rem',
        }}
      >
        <Loading isLoading={isLoading} />
        <IdleTimer
          visibleScreen={visibleScreen}
          setVisibleScreen={setVisibleScreen}
          isLoading={isLoading}
        />
        <Intro
          visibleScreen={visibleScreen}
          setVisibleScreen={setVisibleScreen}
          refreshAllApiData={refreshAllApiData}
        />
        <Catalog
          visibleScreen={visibleScreen}
          setVisibleScreen={setVisibleScreen}
          setDestinationPOIId={setDestinationPOIId}
          catalogEntries={catalogEntriesToUse}
        />
        <Map
          visibleScreen={visibleScreen}
          setVisibleScreen={setVisibleScreen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          destinationPOIId={destinationPOIId}
          setDestinationPOIId={setDestinationPOIId}
        />
        <Schedule
          jobs={jobs}
          timeZone="local"
          dashboard={{
            hidden: true,
          }}
        />
      </IconContext.Provider>
    </div>
  );
};

import { FC } from 'react';

import './Loading.css';

type LoadingProps = {
  isLoading: boolean;
};

export const Loading: FC<LoadingProps> = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div data-testid={LOADING_TEST_ID} className="loading">
        <img src="./spinner.gif" alt="spinner" />
      </div>
    );
  }

  return null;
};

export const LOADING_TEST_ID = 'loading';

import './CatalogListItem.css';
import { useState } from 'react';

import { Text } from '../common/text/Text';
import { AccordionToggle } from '../common/toggle/AccordionToggle';

interface CatalogListItemProps {
  collapsedChildren?: JSX.Element;
  description?: string;
  expandedChildren?: JSX.Element;
  title: string;
  type: string;
}

const TEST_ID = 'catalog-list-item';

const CatalogListItem: React.FC<CatalogListItemProps> = (props) => {
  const [expanded, setExpanded] = useState(false);

  const maxLinesWhenCollapsed = 2;

  const clickHandler = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className="catalog-list-item"
      data-testid={TEST_ID}
      onClick={clickHandler}
    >
      <main className="catalog-list-item-main">
        <div className="catalog-list-item-summary">
          <Text className="catalog-list-item-section" label>
            {props.type}
          </Text>
          <div className="catalog-list-item-row">
            <Text
              className="catalog-list-item-section"
              lineClamp={!expanded ? maxLinesWhenCollapsed : undefined}
            >
              {props.title}
            </Text>
            {!!props.expandedChildren && (
              <div className="catalog-list-item-buttons">
                <AccordionToggle value={expanded} />
              </div>
            )}
          </div>
          {props.collapsedChildren}
        </div>
      </main>
      {expanded && !!props.expandedChildren && (
        <aside className="catalog-list-item-aside">
          {props.expandedChildren}
        </aside>
      )}
    </div>
  );
};

export { CatalogListItem, TEST_ID };

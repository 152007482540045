const getLineClamp = (clamp?: number): React.CSSProperties => {
  return clamp
    ? {
        display: '-webkit-box',
        WebkitLineClamp: clamp,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      }
    : {};
};

export { getLineClamp };

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { KeyboardReactInterface } from 'react-simple-keyboard';

import { VisibleScreen } from '../App';
import background from '../assets/images/background.png';
import { CatalogHeader } from '../components/catalog/CatalogHeader';
import { CatalogList } from '../components/catalog/CatalogList';
import { Filter } from '../components/catalog/Filter.types';
import { Header } from '../components/header/Header';
import { CatalogEntry } from '../data/CatalogEntry';
import { screenviewEvent, useAnalytics } from '../hooks/UseAnalytics';

import './Catalog.css';

type CatalogProps = {
  visibleScreen: VisibleScreen;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  setDestinationPOIId: (destinationPOIId: string) => void;
  catalogEntries?: CatalogEntry[];
};

const Catalog: FC<CatalogProps> = ({
  visibleScreen,
  setVisibleScreen,
  setDestinationPOIId,
  catalogEntries,
}) => {
  const analyticsProvider = useAnalytics();
  const isCatalogVisible = visibleScreen.includes('Catalog');
  const isSearchAll = visibleScreen.includes('All');
  const isReset = visibleScreen.includes('-Reset');
  const [title, setTitle] = useState('Search all');
  const [inputValue, setInputValue] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [filter, setFilter] = useState<Filter>({
    Type: { Session: false, FAQ: false, Place: false },
  });
  const [showPastSession, setShowPastSession] = useState<boolean>(true);

  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const hideKeyboard = useCallback(() => {
    if (isKeyboardVisible) {
      setIsKeyboardVisible(false);
    }
  }, [isKeyboardVisible, setIsKeyboardVisible]);

  const showKeyboard = useCallback(() => {
    if (!isKeyboardVisible) {
      setIsKeyboardVisible(true);
    }
  }, [isKeyboardVisible, setIsKeyboardVisible]);

  const navigateToIntro = useCallback(
    () => setVisibleScreen('Intro'),
    [setVisibleScreen],
  );

  const catalogSearchHandler = useCallback(
    (newSearchKeyword: string) => {
      setSearchKeyword(newSearchKeyword);
    },
    [setSearchKeyword],
  );

  useEffect(() => {
    if (isReset) {
      keyboardRef.current?.clearInput();
      setInputValue('');
      setSearchKeyword('');
      setIsKeyboardVisible(false);
      setIsActive(false);
    }
  }, [isReset]);

  useEffect(() => {
    let newTitle = 'Search all';
    if (visibleScreen.includes('Catalog-Sessions')) {
      newTitle = 'Session Catalog';
    } else if (visibleScreen.includes('Catalog-FAQs')) {
      newTitle = 'FAQs';
    } else if (visibleScreen.includes('Catalog-Navigate')) {
      newTitle = 'Navigate';
    }
    setTitle(newTitle);

    setFilter({
      Type: {
        Session: visibleScreen.includes('Catalog-Sessions'),
        FAQ: visibleScreen.includes('Catalog-FAQs'),
        Place: visibleScreen.includes('Catalog-Navigate'),
      },
    });
  }, [visibleScreen]);

  useEffect(() => {
    if (analyticsProvider && isCatalogVisible && !isSearchAll) {
      void analyticsProvider.record(screenviewEvent(visibleScreen));
    }
  }, [analyticsProvider, isCatalogVisible, isSearchAll, visibleScreen]);

  const handlePastSessionChange = useCallback(() => {
    setShowPastSession((preValue) => !preValue);
  }, []);

  return (
    <div
      data-testid={CATALOG_TEST_ID}
      className={isCatalogVisible ? 'catalog-container' : 'invisible'}
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: `contain`,
      }}
    >
      <div className={`catalogContent ${isActive ? 'reverseContent' : ''}`}>
        <Header onHomeClick={navigateToIntro}>
          <CatalogHeader
            visibleScreen={visibleScreen}
            setVisibleScreen={setVisibleScreen}
            title={title}
            filter={filter}
            keyboardRef={keyboardRef}
            onClickSearchButton={hideKeyboard}
            onSearch={catalogSearchHandler}
            onInputFocus={showKeyboard}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handlePastSessionChange={handlePastSessionChange}
            showPastSession={showPastSession}
          />
        </Header>
        <CatalogList
          key={visibleScreen}
          setVisibleScreen={setVisibleScreen}
          entries={catalogEntries}
          searchKeyword={searchKeyword}
          filter={mapFilterTypesToKeys(filter)}
          setDestinationPOIId={setDestinationPOIId}
          showPastSession={showPastSession}
        />
      </div>
    </div>
  );
};

const mapFilterTypesToKeys = (filter: Filter): Filter => {
  const filterMap: Record<string, string> = {
    Type: 'catalogType',
  };

  const mappedFilter: Filter = {};

  Object.keys(filter).forEach((key) => {
    const newKey = filterMap[key];
    if (newKey) {
      mappedFilter[newKey] = filter[key];
    }
  });

  return mappedFilter;
};

export { Catalog };

export const CATALOG_TEST_ID = 'catalog';

import React, { useCallback } from 'react';

import { VisibleScreen } from '../../App';
import { CatalogEntry } from '../../data/CatalogEntry';
import { Pill } from '../common/pillbox/Pill';
import { Text } from '../common/text/Text';
import { CatalogListItem } from './CatalogListItem';

import './PlaceListItem.css';

interface Place extends CatalogEntry {
  title: string;
  meta: { mappedinid?: string; venue: string };
}

interface PlaceListItemProps {
  place: Place;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  setDestinationPOIId: (destinationPOIId: string) => void;
}

const PlaceListItem: React.FC<PlaceListItemProps> = ({
  place,
  setVisibleScreen,
  setDestinationPOIId,
}) => {
  const hasMappedInId = !!place.meta.mappedinid;

  const handleGetDirectionsClick = useCallback(() => {
    if (place.meta.mappedinid) {
      setDestinationPOIId(place.meta.mappedinid);
      setVisibleScreen('Map');
    }
  }, [place.meta.mappedinid, setDestinationPOIId, setVisibleScreen]);

  const getCollapsedChildren = () => {
    const { venue } = place.meta;
    if (venue) {
      return (
        <div className="place__venue">
          <p>
            <Text className="venue-text">{venue}</Text>
          </p>
          {hasMappedInId && (
            <Pill
              label="Get directions"
              onClick={handleGetDirectionsClick}
              selected={false}
            />
          )}
        </div>
      );
    }
  };

  return (
    <CatalogListItem
      type="navigate"
      title={place.title}
      collapsedChildren={getCollapsedChildren()}
    />
  );
};

export { PlaceListItem };
export type { Place };

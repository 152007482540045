import { FC } from 'react';

import chevronRight from '../../assets/svg/chevron-right.svg';
import { Text } from '../common/text/Text';
import './MenuItem.css';

const MenuItem: FC<MenuItemProps> = ({ title, subtitle, icon, onClick }) => (
  <div
    className={onClick ? 'menu-item-button' : 'menu-item-info'}
    role={onClick ? 'button' : undefined}
    onClick={onClick}
  >
    <div className="menu-item-row">
      <div className="menu-item-content">
        <div className="menu-item-icon-wrapper">
          <img className="menu-item-icon" src={icon} alt={title} />
        </div>
        <div className="menu-item-text">
          <Text className="menu-item-title">{title}</Text>
          <Text className="menu-item-subtitle">{subtitle}</Text>
        </div>
      </div>
      {onClick && (
        <div className="menu-item-chevron-wrapper">
          <img className="menu-item-chevron" src={chevronRight} alt="go" />
        </div>
      )}
    </div>
  </div>
);

export { MenuItem };

export type MenuItemProps = {
  title: string;
  subtitle: string | JSX.Element;
  icon: string;
  onClick?: () => void;
};

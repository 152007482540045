import { FC, useCallback } from 'react';

import { VisibleScreen } from '../../App';
import { Session } from '../../data/CatalogEntry';
import { Pill } from '../common/pillbox/Pill';
import { Text } from '../common/text/Text';
import { CatalogListItem } from './CatalogListItem';
import { SessionItemTime } from './sessionListItem/SessionItemTime';

import './SessionListItem.css';

interface SessionListItemProps {
  session: Session;
  setDestinationPOIId: (destinationPOIId: string) => void;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
}

const removeHtmlTags = (data: string) => {
  return data.replace(/<[^>]+>/g, '');
};

const SessionListItem: FC<SessionListItemProps> = (props) => {
  const hasRoomId = !!props.session.roomId;

  const handleGetDirectionsClick = useCallback(() => {
    if (props.session.roomId) {
      props.setDestinationPOIId(props.session.roomId);
      props.setVisibleScreen('Map');
    }
  }, [props]);

  const getCollapsedChildren = () => {
    if (props.session.sessionTime) {
      return (
        <SessionItemTime
          sessionTime={props.session.sessionTime}
          venue={props.session.Venue}
        />
      );
    }
  };

  const getExpandedChildren = () => {
    if (props.session.abstract) {
      return (
        <div className="catalog-list-item-row">
          <p>
            <Text className="session-abstract">
              {removeHtmlTags(props.session.abstract)}
            </Text>
          </p>
          {hasRoomId && (
            <Pill
              selected={false}
              label="Get directions"
              onClick={handleGetDirectionsClick}
            />
          )}
        </div>
      );
    }
  };

  return (
    <CatalogListItem
      type="session catalog"
      title={`${props.session.abbreviation}: ${props.session.title}`}
      collapsedChildren={getCollapsedChildren()}
      expandedChildren={getExpandedChildren()}
    />
  );
};

export { SessionListItem };
export type { Session };

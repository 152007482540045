import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';

import backButton from '../../assets/svg/back-button.svg';
import headerLogo from '../../assets/svg/header-logo.svg';
import homeButton from '../../assets/svg/home-button.svg';
import searchButton from '../../assets/svg/search-button.svg';
import './Header.css';

interface HeaderProps extends React.PropsWithChildren {
  iconRight?: JSX.Element;
  isBackButton?: boolean;
  onClickIconRight?: () => void;
  onHomeClick?: () => void;
  onSearchClick?: () => void;
}

export const Header: FunctionComponent<HeaderProps> = ({
  children,
  iconRight,
  isBackButton,
  onClickIconRight,
  onHomeClick,
  onSearchClick,
}) => {
  const [dateTime, setDateTime] = useState(moment());

  useEffect(() => {
    setDateTime(moment());

    const interval = setInterval(() => {
      setDateTime(moment());
    }, timeUpdateFrequency);

    return () => clearInterval(interval);
  }, []);

  return (
    <div data-testid={NAVIGATION_HEADER_TEST_ID} className="navigation-header">
      <div className="header-row">
        <div className="header-left">
          {onHomeClick && (
            <div
              data-testid={HOME_BUTTON_TEST_ID}
              className="home-button-wrapper"
              role="button"
              onClick={onHomeClick}
            >
              <img
                className="home-button"
                src={isBackButton ? backButton : homeButton}
                alt="home button"
              />
            </div>
          )}
          <img className="header-logo" src={headerLogo} alt="aws logo" />
        </div>
        <div className="header-right">
          <div className="header-date">{dateTime.format('MMMM D, YYYY')}</div>
          <div className="header-time">{dateTime.format('h:mm A')}</div>
          {iconRight && (
            <div
              className="icon-right-button"
              role="button"
              onClick={onClickIconRight}
            >
              {iconRight}
            </div>
          )}
          {onSearchClick && (
            <div
              className="search-button-wrapper"
              role="button"
              onClick={onSearchClick}
            >
              <img
                className="search-button"
                src={searchButton}
                alt="search button"
              />
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export const timeUpdateFrequency = 5000;

export const NAVIGATION_HEADER_TEST_ID = 'navigation-header';
export const HOME_BUTTON_TEST_ID = 'header-home-button';

import moment from 'moment-timezone';
import { FC } from 'react';

import { SessionTime } from '../../../data/CatalogEntry';
import { Text } from '../../common/text/Text';

interface SessionTimeProps {
  sessionTime: SessionTime;
  venue: string;
}

const SessionItemTime: FC<SessionTimeProps> = ({ sessionTime, venue }) => {
  const { date, time, timezone } = sessionTime;

  const momentDate = moment.tz(`${date} ${time}`, 'YYYY-MM-DD HH:mm', timezone);
  const dateString = momentDate.format('MMMM D, YYYY');
  const timeString = momentDate.format('h:mm A');

  return !!date && !!time ? (
    <div className="session-date">
      {venue && <Text className="session-date-text">{venue}</Text>}
      <Text className="session-date-text">{dateString}</Text>
      <Text className="session-date-text">{timeString}</Text>
    </div>
  ) : (
    <div />
  );
};

export { SessionItemTime };

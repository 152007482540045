import { Text } from '../text/Text';
import './Pill.css';

interface PillProps {
  label: string;
  selected: boolean;
  isTall?: boolean;
  onClick?: (pill: string) => void;
}

const Pill: React.FC<PillProps> = (props) => {
  let boxClassName = `pill${props.selected ? ' pill__active' : ''}`;
  let textClassName = 'pill-label';
  const isSecondary =
    props.label === 'Get directions' || props.label === 'Show more';
  if (props.isTall) {
    boxClassName = `${boxClassName} pill__tall`;
  }
  if (isSecondary) {
    boxClassName = `${boxClassName} pill__directions`;
    textClassName = `${textClassName} pill-label__directions`;
  }

  const clickHandler = () => {
    if (props.onClick) {
      props.onClick(props.label);
    }
  };

  return (
    <div
      data-testid={`${props.label}-pill`}
      className={boxClassName}
      onClick={clickHandler}
    >
      <Text className={textClassName}>{props.label}</Text>
    </div>
  );
};

export { Pill };

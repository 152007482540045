import { Analytics as AmplifyAnalytics } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { VisibleScreen } from '../App';
import { config } from '../config';
import { analyticsEventName, analyticsEventType } from '../screens/Intro';
import { useAmplify } from './UseAmplify';

const { EVENT_ID: aws_event_id } = config;

const mapVisibleScreenToView: Record<VisibleScreen, string> = {
  Intro: 'kiosk_view',
  Map: 'map_kiosk',
  'Map-Reset': 'map_kiosk',
  'Catalog-All': 'all_items_kiosk',
  'Catalog-All-Reset': 'all_items_kiosk',
  'Catalog-Sessions': 'session_catalog_kiosk',
  'Catalog-Sessions-Reset': 'session_catalog_kiosk',
  'Catalog-FAQs': 'faqs_kiosk',
  'Catalog-FAQs-Reset': 'faqs_kiosk',
  'Catalog-Navigate': 'places_kiosk',
  'Catalog-Navigate-Reset': 'places_kiosk',
};

const commonAttributes = {
  source_app: 'KioskApp',
  tags: 'kiosk',
  feature: 'kiosk',
  aws_event_id,
  aws_event_name: analyticsEventName,
  aws_event_type: analyticsEventType,
};

export interface AnalyticsEvent {
  name: string;
  attributes: Record<string, string>;
  metrics: Record<string, number>;
}

export interface AnalyticsProvider {
  record: (event: AnalyticsEvent) => Promise<unknown>;
}

export class Analytics {
  private readonly analyticsProvider: AnalyticsProvider;

  constructor(analyticsProvider: AnalyticsProvider = AmplifyAnalytics) {
    this.analyticsProvider = analyticsProvider;
  }

  record(event: AnalyticsEvent): Promise<unknown> {
    return this.analyticsProvider.record(event);
  }
}

export const useAnalytics = () => {
  const isAmplifyReady = useAmplify();
  const [analyticsProvider, setAnalyticsProvider] = useState<Analytics | null>(
    null,
  );

  useEffect(() => {
    if (isAmplifyReady) {
      setAnalyticsProvider(new Analytics(AmplifyAnalytics));
    }
  }, [isAmplifyReady]);

  return analyticsProvider;
};

export const sessionStartEvent = {
  name: 'session_start',
  attributes: {
    ...commonAttributes,
    view: mapVisibleScreenToView.Intro,
  },
  metrics: {},
};

export const screenviewEvent = (visibleScreen: VisibleScreen) => ({
  name: 'screenview',
  attributes: {
    ...commonAttributes,
    view: mapVisibleScreenToView[visibleScreen],
  },
  metrics: {},
});

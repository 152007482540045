import { FC } from 'react';

import { Session } from '../../data/CatalogEntry';
import { Text } from '../common/text/Text';
import { SessionItemTime } from './sessionListItem/SessionItemTime';

import './PastSessionListItem.css';

interface PastSessionListItemProps {
  session: Session;
}

const PastSessionListItem: FC<PastSessionListItemProps> = ({ session }) => {
  return (
    <div className="past-session-container">
      <Text className="type" label>
        Past session
      </Text>
      <Text className="title">{session.title}</Text>
      {session.sessionTime && (
        <SessionItemTime
          sessionTime={session.sessionTime}
          venue={session.Venue}
        />
      )}
    </div>
  );
};

export { PastSessionListItem };

import { getLineClamp } from '../../../theme/css-utils';
import './Text.css';

interface TextProps extends React.PropsWithChildren {
  lineClamp?: number;
  className?: string;
  label?: boolean;
  large?: boolean;
  medium?: boolean;
  testId?: string;
}

/**
 * A wrapper element which adds the AmazonEmber `font-family`. For font sizing,
 * wrap this element in the appropriate html tag (`h1`, `h2`, `p`, etc.) and it will
 * scale according to the screen width. If custom sizing is needed, use the
 * `rem` unit.
 */
const Text: React.FC<TextProps> = (props) => {
  const className = buildClassName(props);
  return (
    <span
      data-testid={props.testId}
      className={className}
      style={getLineClamp(props.lineClamp)}
    >
      {props.children}
    </span>
  );
};

function buildClassName(props: React.PropsWithChildren<TextProps>) {
  const classes = ['text'];

  if (props.className) {
    classes.push(props.className);
  }

  if (props.label) {
    classes.push('text-label');
  }

  if (props.large) {
    classes.push('text-large');
  }

  if (props.medium) {
    classes.push('text-medium');
  }

  return classes.join(' ');
}

export { Text };

import { FC, useCallback, useEffect, useRef } from 'react';

import { VisibleScreen } from '../../../App';
import { useTimer } from '../../../hooks/UseTimer';
import { initializeMap } from '../../../screens/Map';
import { TimeoutModal } from './TimeoutModal';

const IDLE_SECONDS = 25;
const COUNT_DOWN = 15;
const SECOND_IN_MS = 1000;

type IdleTimerProps = {
  visibleScreen: VisibleScreen;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  isLoading: boolean;
};

export const IdleTimer: FC<IdleTimerProps> = ({
  visibleScreen,
  setVisibleScreen,
  isLoading,
}) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const onCountDownComplete = () => {
    void initializeMap();
    handleStop();
    setVisibleScreen('Intro');
  };
  const { status, duration, elapsed, handleStart, handleStop } = useTimer({
    onTimeout: onCountDownComplete,
  });

  const goBackToHome = useCallback(() => {
    if (timeout.current) clearTimeout(timeout.current);
    handleStart(COUNT_DOWN);
  }, [handleStart]);

  const restartAutoReset = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      goBackToHome();
    }, SECOND_IN_MS * IDLE_SECONDS);
  }, [goBackToHome]);

  const closeModal = useCallback(() => {
    handleStop();
    restartAutoReset();
  }, [restartAutoReset, handleStop]);

  useEffect(() => {
    if (isLoading || visibleScreen === 'Intro') {
      return;
    }

    restartAutoReset();

    document.addEventListener('touchend', restartAutoReset);
    document.addEventListener('keyup', restartAutoReset);
    document.addEventListener('mouseup', restartAutoReset);
    document.addEventListener('scroll', restartAutoReset, true);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        document.removeEventListener('touchend', restartAutoReset);
        document.removeEventListener('keyup', restartAutoReset);
        document.removeEventListener('mouseup', restartAutoReset);
        document.removeEventListener('scroll', restartAutoReset);
      }
    };
  }, [isLoading, visibleScreen, restartAutoReset]);

  return (
    <TimeoutModal
      closeModal={closeModal}
      seconds={duration - elapsed}
      isVisible={status === 'RUNNING'}
    />
  );
};

import './AccordionToggle.css';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

interface AccordionToggleProps {
  value: boolean;
}

const AccordionToggle: React.FC<AccordionToggleProps> = ({ value }) => {
  return (
    <button className="accordion-toggle">
      {value ? <MdExpandLess /> : <MdExpandMore />}
    </button>
  );
};

export { AccordionToggle };

import { FC } from 'react';

import { Text } from '../common/text/Text';
import './NoResults.css';

const NoResults: FC = () => (
  <div className="no-results-container" data-testid="catalog-list-no-results">
    <Text className="no-results-title">No matching results.</Text>
    <Text className="no-results-subtitle">Please try another search.</Text>
  </div>
);

export { NoResults };

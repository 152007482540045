import { PointrInteractiveMap } from '@mobiquity/pointr-interactive-map';
import { FC, useCallback, useEffect, useState } from 'react';

import { VisibleScreen } from '../App';
import background from '../assets/images/background.png';
import { Header } from '../components/header/Header';
import { config } from '../config';
import { useQuery } from '../hooks/UseQuery';

import './Map.css';

const VISIBLE_OPACITY = 1;
const INVISIBLE_OPACITY = 0;
type MapProps = {
  visibleScreen: VisibleScreen;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  destinationPOIId: string;
  setDestinationPOIId: (destinationPOIId: string) => void;
};

const { ENV_NAME, EVENT_ID, POINTR_INITIAL_POI, POINTR_SITE_INTERNAL_ID } =
  config;
let map: PointrInteractiveMap | null = null;
let isMapInitialized = false;

const baseZoomLevel = 18;
const additionalZoomLevelDivider = 1000;
const zoomLevel =
  baseZoomLevel + Math.floor(window.innerHeight / additionalZoomLevelDivider);

const mapClear = () => {
  map?.unhighlight();
  map?.closeNavigation();
};

const getPOIById = async (
  poiId: string,
): Promise<PointrWebSDK.POI | undefined> => {
  let poi: PointrWebSDK.POI | undefined;
  if (poiId) {
    poi = await map?.getPOI(poiId);
    if (!poi) {
      poi = await map?.findPOI(poiId, undefined, POINTR_SITE_INTERNAL_ID);
    }
  }
  return poi;
};

const focusPOI = async (poi: PointrWebSDK.POI | undefined): Promise<void> => {
  mapClear();
  if (poi) {
    await map?.highlight(poi);
    map?.centerAndZoom(poi, zoomLevel);
  }
};

const mapResetToInitialPOI = async () => {
  mapClear();
  const poi = await getPOIById(POINTR_INITIAL_POI);
  if (poi) {
    await focusPOI(poi);
  }
};

const navigateToPOI = async (
  originPOIId = '',
  destinationPOIId = '',
): Promise<void> => {
  mapClear();
  const originPOI = await getPOIById(originPOIId);
  const destinationPOI = await getPOIById(destinationPOIId);
  if (originPOI && destinationPOI) {
    await focusPOI(originPOI);
    map?.setOrigin(originPOI);
    map?.setDestination(destinationPOI);
    map?.hidePoiDetails();
    map?.navigate();
  } else if (!originPOI && destinationPOI) {
    await focusPOI(destinationPOI);
  }
};

export const initializeMap = async (): Promise<void> => {
  if (!isMapInitialized) {
    isMapInitialized = true;
    map = new PointrInteractiveMap({
      refreshIntervalMs: 1800000,
      shouldEnableSearch: true,
      siteInternalId: POINTR_SITE_INTERNAL_ID,
      environment: ENV_NAME === 'prod' ? 'production' : 'development',
      siteExternalId: EVENT_ID,
      rotation: 0,
      shouldEnableNavigation: false,
    });
  }
  map?.init('pointr-map');
  await mapResetToInitialPOI();
};

export const Map: FC<MapProps> = ({
  visibleScreen,
  setVisibleScreen,
  isLoading,
  setIsLoading,
  destinationPOIId,
  setDestinationPOIId,
}) => {
  const [goBackRoute, setGoBackRoute] = useState<VisibleScreen>('Catalog-All');

  const isMapReset = visibleScreen === 'Map-Reset';
  const isMapVisible = visibleScreen.includes('Map');
  const query = useQuery();
  const originPoiInternalId = query.get('originPoiInternalId') ?? '';

  const goBack = useCallback(
    () => setVisibleScreen(goBackRoute),
    [setVisibleScreen, goBackRoute],
  );

  const handleRefresh = useCallback(() => {
    void initializeMap();
  }, []);

  // Keep track of the latest goBackRoute
  useEffect(() => {
    if (visibleScreen.includes('Catalog')) {
      setGoBackRoute(visibleScreen.replace('-Reset', '') as VisibleScreen);
    }
  }, [visibleScreen, setGoBackRoute]);

  // On initial app load, after initializing the map, navigate to Intro screen
  useEffect(() => {
    if (isLoading) {
      void initializeMap().then(() => {
        setVisibleScreen('Intro');
        setIsLoading(false);
      });
    }
  }, [isLoading, setVisibleScreen, setIsLoading]);

  // On a map reset, reset the map view to the initial POI
  // Otherwise, navigate to the given POI
  useEffect(() => {
    if (isMapReset) {
      setDestinationPOIId('');
      void mapResetToInitialPOI();
    } else {
      void navigateToPOI(originPoiInternalId, destinationPOIId);
    }
  }, [
    isMapReset,
    visibleScreen,
    setDestinationPOIId,
    originPoiInternalId,
    destinationPOIId,
    setIsLoading,
  ]);

  // If the map is the not right size, reload the map
  useEffect(() => {
    const canvas = window.document.querySelector('.mapboxgl-canvas');
    if (canvas) {
      const canvasHeight = canvas.clientHeight;
      const wrapperHeight =
        window.document.querySelector('.map-wrapper')?.clientHeight;
      if (canvasHeight && wrapperHeight && canvasHeight < wrapperHeight) {
        handleRefresh();
      }
    }
  }, [isMapVisible, handleRefresh]);

  return (
    <div
      className="map-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: `contain`,
        opacity: isMapVisible ? VISIBLE_OPACITY : INVISIBLE_OPACITY,
      }}
    >
      <Header isBackButton onHomeClick={goBack} />
      <div className="map-wrapper">
        <div id="pointr-map" />
      </div>
    </div>
  );
};

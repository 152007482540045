import { FC, useCallback, useEffect, useState } from 'react';

import { VisibleScreen } from '../App';
import book from '../assets/svg/book.svg';
import geopin from '../assets/svg/geopin.svg';
import help from '../assets/svg/help.svg';
import wifi from '../assets/svg/wifi.svg';
import video from '../assets/video/wave-video.mp4';
import { Text } from '../components/common/text/Text';
import { Header } from '../components/header/Header';
import { MenuItem, MenuItemProps } from '../components/intro/MenuItem';
import { sessionStartEvent, useAnalytics } from '../hooks/UseAnalytics';

import './Intro.css';

type IntroProps = {
  visibleScreen: VisibleScreen;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  refreshAllApiData: () => Promise<void>;
};

export const Intro: FC<IntroProps> = ({
  visibleScreen,
  setVisibleScreen,
  refreshAllApiData,
}) => {
  const analyticsProvider = useAnalytics();
  const isIntroVisible = visibleScreen.includes('Intro');
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    if (analyticsProvider) {
      void analyticsProvider.record(sessionStartEvent);
    }
  }, [analyticsProvider]);

  const handleLoadedData = useCallback(
    () => setIsVideoLoaded(true),
    [setIsVideoLoaded],
  );

  const navigate = useCallback(
    (newScreen: VisibleScreen) => {
      if (isVideoLoaded) {
        void refreshAllApiData();
        setVisibleScreen(newScreen);
      }
    },
    [isVideoLoaded, refreshAllApiData, setVisibleScreen],
  );

  const navigateToSessionsReset = useCallback(
    () => navigate('Catalog-Sessions-Reset'),
    [navigate],
  );
  const navigateToFAQsReset = useCallback(
    () => navigate('Catalog-FAQs-Reset'),
    [navigate],
  );
  const navigateToPlacesReset = useCallback(
    () => navigate('Catalog-Navigate-Reset'),
    [navigate],
  );

  const menuItems: MenuItemProps[] = [
    {
      title: 'Navigate',
      subtitle: `Find your way around the ${campusName} campus.`,
      icon: geopin,
      onClick: navigateToPlacesReset,
    },
    {
      title: 'Session Catalog',
      subtitle:
        'Browse the Session Catalog to find events and programs that interest you.',
      icon: book,
      onClick: navigateToSessionsReset,
    },
    {
      title: 'FAQs',
      subtitle:
        'Find information on topics such as health and safety, registration, accessibility, and more.',
      icon: help,
      onClick: navigateToFAQsReset,
    },
    {
      title: 'Get free wifi on your personal device',
      subtitle: (
        <span>
          {"Connect to network '"}
          <span className="bold">{wifiSSID}</span>
          {"' using password '"}
          <span className="bold">{wifiPassword}</span>
          {"' on your personal device."}
        </span>
      ),
      icon: wifi,
    },
  ];

  return (
    <div className={isIntroVisible ? 'intro-container' : 'invisible'}>
      <video
        autoPlay
        loop
        muted
        preload="auto"
        playsInline
        className="intro-video-wrapper"
        onLoadedData={handleLoadedData}
      >
        <source src={video} type="video/mp4" />
      </video>
      <Header />
      <div className="intro-menu">
        <Text className="event-name">{eventNameLabel}</Text>
        <Text className="menu-title">Information Center</Text>
        {menuItems.map((menuItem) => (
          <MenuItem key={`${menuItem.title}`} {...menuItem} />
        ))}
      </div>
    </div>
  );
};

// TODO: need to update environment variables below
export const analyticsEventName = 'AWS re:Invent 2023';
export const analyticsEventType = 'Key Event';
export const eventNameLabel = 're:Invent 2023';
export const campusName = 'AWS re:Invent';
export const wifiSSID = 'Signage23';
export const wifiPassword = 'Pr3ttyP1x3ls23';
// TODO: need to update environment variables above

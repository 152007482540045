import { VisibleScreen } from '../../../App';
import { Pill } from './Pill';
import { PillUpdate } from './PillUpdate.types';
import './PillboxCategory.css';

interface PillboxCategoryProps {
  category: string;
  pills: string[];
  selectedPills?: string[];
  onPillChange?: (change: PillUpdate) => void;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
}

const PillboxCategory: React.FC<PillboxCategoryProps> = (props) => {
  const isPillSelected = (pill: string) => {
    return !!props.selectedPills?.includes(pill);
  };

  const pillClickHandler = (pill: string) => {
    if (props.onPillChange) {
      props.onPillChange({
        category: props.category,
        pill,
        selected: !props.selectedPills?.includes(pill),
      });
    }
  };

  return (
    <div className="pillbox-category">
      <div className="pillbox-category__pills">
        <div className="pillbox-category__pill-wrapper">
          <Pill
            selected={props.selectedPills?.length === 0}
            label="View all"
            onClick={() => props.setVisibleScreen('Catalog-All')}
          />
        </div>
        {[props.pills[2], props.pills[0], props.pills[1]].map((pill) => {
          let label = pill;
          if (pill === 'Session') {
            label = 'Session Catalog';
          } else if (pill === 'FAQ') {
            label = 'FAQs';
          } else if (pill === 'Place') {
            label = 'Navigate';
          }

          return (
            <div className="pillbox-category__pill-wrapper" key={pill}>
              <Pill
                selected={isPillSelected(pill)}
                label={label}
                onClick={() => pillClickHandler(pill)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { PillboxCategory };

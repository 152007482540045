import moment from 'moment-timezone';
import { FC, useState } from 'react';

import { VisibleScreen } from '../../App';
import { CatalogEntry } from '../../data/CatalogEntry';
import { useDeviceDetect } from '../../hooks/UseDeviceDetect';
import { Pill } from '../common/pillbox/Pill';
import { FAQ, FAQListItem } from './FaqListItem';
import { Filter, FilterValues } from './Filter.types';
import { NoResults } from './NoResults';
import { PastSessionListItem } from './PastSessionListItem';
import { Place, PlaceListItem } from './PlaceListItem';
import { Session, SessionListItem } from './SessionListItem';

import './CatalogList.css';

interface CatalogListProps {
  entries?: CatalogEntry[];
  filter?: Filter;
  searchKeyword?: string;
  setDestinationPOIId: (destinationPOIId: string) => void;
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  showPastSession: boolean;
}

const DEFAULT_MAX_LIST_SIZE = 25;
const DEFAULT_LIST_SIZE_INCREMENT = 25;
const SESSION_TIME_DIFFERENCE_MIN = -15;

const CatalogList: FC<CatalogListProps> = ({
  entries,
  filter,
  searchKeyword,
  setDestinationPOIId,
  setVisibleScreen,
  showPastSession,
}) => {
  const { isIpad } = useDeviceDetect();
  const [maxListSize, setMaxListSize] = useState(DEFAULT_MAX_LIST_SIZE);

  if (!entries) {
    return <div className="catalog-list" />;
  }

  const hasMoreEntries = entries.length > maxListSize;

  const showMoreHandler = () => {
    setMaxListSize(maxListSize + DEFAULT_LIST_SIZE_INCREMENT);
  };

  let filteredEntries = entries;

  if (searchKeyword) {
    filteredEntries = entries.filter((entry) =>
      entryMatchesSearchKeyword(entry, searchKeyword),
    );
  }

  if (filter && !isEveryFilterOff(filter)) {
    filteredEntries = filteredEntries.filter((entry) =>
      entryMatchesAllFilters(entry, filter),
    );
  }

  const displayedEntries = filteredEntries
    .slice(0, maxListSize)
    .map((entry, index) =>
      getListItemForCatalogEntry(
        entry,
        index,
        setDestinationPOIId,
        setVisibleScreen,
        showPastSession,
      ),
    )
    .filter((entryElement) => !!entryElement);

  const noEntriesToDisplay = displayedEntries.length === 0;

  const margin = isIpad ? '0 64px' : '0 56px 0 64px';

  return (
    <div className="catalog-list" data-testid="catalog-list" style={{ margin }}>
      {noEntriesToDisplay ? <NoResults /> : displayedEntries}
      {hasMoreEntries && filteredEntries.length > maxListSize && (
        <div className="catalog-list-show-more" onClick={showMoreHandler}>
          <Pill label="Show more" selected={false} />
        </div>
      )}
    </div>
  );
};

function getListItemForCatalogEntry(
  entry: CatalogEntry,
  index: number,
  setDestinationPOIId: (destinationPOIId: string) => void,
  setVisibleScreen: (visibleScreen: VisibleScreen) => void,
  showPastSession: boolean,
): JSX.Element | undefined {
  if (entry.catalogType === 'Session') {
    const session = entry as Session;
    // Without date and time will consider as future session
    let isPastSession = false;
    if (
      session.sessionTime?.date &&
      session.sessionTime.time &&
      session.sessionTime.timezone
    ) {
      const { date, time, timezone } = session.sessionTime;
      const momentSessionDate = moment.tz(
        `${date} ${time}`,
        'YYYY-MM-DD HH:mm',
        timezone,
      );
      const momentNow = moment().tz(timezone);
      const difference = momentSessionDate.diff(momentNow, 'minutes');

      if (SESSION_TIME_DIFFERENCE_MIN >= difference) {
        // Past session will have different design
        isPastSession = true;
        if (!showPastSession) {
          // Hide the past session tile
          return;
        }
      }
    }

    return isPastSession ? (
      <PastSessionListItem session={session} />
    ) : (
      <SessionListItem
        key={index}
        session={session}
        setDestinationPOIId={setDestinationPOIId}
        setVisibleScreen={setVisibleScreen}
      />
    );
  }

  if (entry.catalogType === 'FAQ') {
    return <FAQListItem key={index} faq={entry as FAQ} />;
  }

  if (entry.catalogType === 'Place') {
    return (
      <PlaceListItem
        key={index}
        place={entry as Place}
        setDestinationPOIId={setDestinationPOIId}
        setVisibleScreen={setVisibleScreen}
      />
    );
  }
}

const entryMatchesSearchKeyword = (
  entry: CatalogEntry,
  searchKeyword?: string,
) => {
  return !searchKeyword ? true : entryHasPropValue(entry, searchKeyword);
};

const entryHasPropValue = (entry: CatalogEntry, propValue: string) => {
  return Object.values(entry).some((value) => {
    if (typeof value !== 'string') {
      return false;
    }
    return value.trim().toLowerCase().includes(propValue);
  });
};

const entryMatchesAllFilters = (entry: CatalogEntry, filters: Filter) => {
  return Object.entries(filters).every((filterEntry) => {
    return entryMatchesFilter(entry, filterEntry);
  });
};

const isEveryFilterOff = (filters: Filter) => {
  const filterValues = Object.values(filters);
  let filterValueSettings: boolean[] = [];

  filterValues.forEach((fv) => {
    filterValueSettings = filterValueSettings.concat(Object.values(fv));
  });

  return !filterValueSettings.some((setting) => setting);
};

const entryMatchesFilter = (
  entry: CatalogEntry,
  [filterName, filterValue]: [string, FilterValues],
) => {
  const entryProp = entry[filterName];
  if (entryProp && typeof entryProp === 'string') {
    return filterValue[entryProp];
  }
  return false;
};

export { CatalogList };

import { PastSessionSwitch } from './PastSessionSwitch';
import { PillUpdate } from './PillUpdate.types';
import { PillboxCategory } from './PillboxCategory';
import { VisibleScreen } from '../../../App';
import { Filter } from '../../catalog/Filter.types';
import './Pillbox.css';

interface PillboxProps {
  setVisibleScreen: (visibleScreen: VisibleScreen) => void;
  onPillChange?: (change: PillUpdate) => void;
  values?: Filter;
  handlePastSessionChange: () => void;
  showPastSession: boolean;
}

const Pillbox: React.FC<PillboxProps> = (props) => {
  const valueForCategory = (categoryName: string): string[] => {
    if (!props.values) {
      return [];
    }

    const categoryValues = props.values[categoryName];

    return Object.entries(categoryValues)
      .map(([pillName, pillSelected]) => {
        return pillSelected ? pillName : '';
      })
      .filter((pill) => !!pill);
  };

  return (
    <div className="pillbox">
      {props.values &&
        Object.entries(props.values).map(([category, pills]) => (
          <PillboxCategory
            key={category}
            category={category}
            pills={Object.keys(pills)}
            selectedPills={valueForCategory(category)}
            onPillChange={props.onPillChange}
            setVisibleScreen={props.setVisibleScreen}
          />
        ))}
      <PastSessionSwitch
        pills={props.values?.Type ?? {}}
        handlePastSessionChange={props.handlePastSessionChange}
        showPastSession={props.showPastSession}
      />
    </div>
  );
};

export { Pillbox };

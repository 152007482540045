import { useEffect, useState } from 'react';

interface UseDeviceDetectReturn {
  isIpad: boolean;
}

const useDeviceDetect = (): UseDeviceDetectReturn => {
  const [isIpad, setIsIpad] = useState<boolean>(false);

  useEffect(() => {
    const requiredMinTouchPoint = 1;
    const {userAgent} = window.navigator;

    if (
      userAgent.includes('iPad') ||
      (/Macintosh/.test(userAgent) &&
        window.navigator.maxTouchPoints > requiredMinTouchPoint)
    ) {
      setIsIpad(true);
    }
  }, []);

  return { isIpad };
};

export { useDeviceDetect };
export type { UseDeviceDetectReturn };

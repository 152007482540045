import { PropsWithChildren, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

import { Pill } from '../pillbox/Pill';
import { Text } from '../text/Text';
import './TimeoutModal.css';

interface TimeOutModalProps {
  isVisible: boolean;
  closeModal: () => void;
  seconds: number;
}

export const TimeoutModal = ({
  isVisible,
  closeModal,
  seconds,
}: PropsWithChildren<TimeOutModalProps>) => {
  // Hide the keyboard when TimeoutModal becomes visible
  useEffect(() => {
    if (
      isVisible &&
      document.activeElement &&
      'blur' in document.activeElement
    ) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [isVisible]);

  return (
    <div
      data-testid="countdown-modal"
      className={isVisible ? 'countdown-modal' : 'display-none'}
    >
      <div className="countdown-content">
        <MdClose
          data-testid="closeIcon"
          size={'1.5rem'}
          onClick={closeModal}
          className="icon"
        />
        <Text className="title">Anyone there?</Text>
        <Text className="subtitle">This kiosk will reset soon.</Text>
        <div className="countdown-wrapper">
          <Text className="countdown">{seconds}</Text>
        </div>
        <div className="countdown-modal-footer">
          <Text className="countdown-modal-footer-text">
            Still using this kiosk?
          </Text>
          <Pill
            isTall
            label="Yes, continue"
            selected={false}
            onClick={closeModal}
          />
        </div>
      </div>
    </div>
  );
};

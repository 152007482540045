import { Filter } from '../../catalog/Filter.types';
import { Switch } from '../switch/Switch';

interface PastSessionSwitchProps {
  pills: Filter | Record<string, unknown>;
  handlePastSessionChange: () => void;
  showPastSession: boolean;
}

const PastSessionSwitch: React.FC<PastSessionSwitchProps> = ({
  pills,
  handlePastSessionChange,
  showPastSession,
}) => {
  return !pills.FAQ && !pills.Place ? (
    <Switch
      id="past-session"
      label="Show past session"
      onChange={handlePastSessionChange}
      checked={showPastSession}
    />
  ) : null;
};

export { PastSessionSwitch };
